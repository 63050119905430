import { TUI_VERSION, EMPTY_ARRAY, CHAR_NO_BREAK_SPACE } from '@taiga-ui/cdk';
const TUI_CACHE_BUSTING_PAYLOAD = `?v=${TUI_VERSION}`;

/**
 * @deprecated
 * TODO: delete in v4.0
 */
const TUI_DECIMAL_SYMBOLS = [',', '.'];
const DEFAULT_ICONS_PATH = name => name.includes('.svg#') ? name : `#${name}`;
const TUI_DEFAULT_MARKER_HANDLER = () => EMPTY_ARRAY;
const TUI_DEFAULT_NUMBER_FORMAT = {
  decimalLimit: Infinity,
  decimalSeparator: ',',
  thousandSeparator: CHAR_NO_BREAK_SPACE,
  zeroPadding: true,
  rounding: 'truncate',
  decimal: 'not-zero'
};
const tuiEditingKeys = ['Spacebar', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Left', 'Right', 'End', 'Home'];

/**
 * An event indicating that async data for expand has finished loading.
 * Dispatch to finish loading states for {@link TuiExpandComponent}.
 */
const TUI_EXPAND_LOADED = 'tui-expand-loaded';
/**
 * An event for scrolling an element into view within {@link TuiScrollbarComponent}.
 */
const TUI_SCROLL_INTO_VIEW = 'tui-scroll-into-view';
/**
 * An event to notify {@link TuiScrollbarComponent} that
 * it should control a nested element.
 */
const TUI_SCROLLABLE = 'tui-scrollable';
/**
 * An event indicating and error during icon loading in {@link TuiSvgComponent}.
 */
const TUI_ICON_ERROR = 'tui-icon-error';
const TUI_HINT_DIRECTIONS = ['bottom-left', 'bottom', 'bottom-right', 'top-left', 'top', 'top-right', 'left-top', 'left', 'left-bottom', 'right-top', 'right', 'right-bottom'];

/**
 * @deprecated Use {@link https://github.com/taiga-family/maskito Maskito}
 * TODO: delete in v4.0
 */
const MASK_CARET_TRAP = '[]';
const TUI_DIGIT_REGEXP = /\d/;
const TUI_NON_DIGIT_REGEXP = /\D/;
const TUI_NON_DIGITS_REGEXP = /\D+/g;
const TUI_LEADING_ZEROES_REGEXP = /^0+/;
const TUI_MASK_SYMBOLS_REGEXP = /[ \-_()]/g;
const TUI_LAST_PUNCTUATION_MARK_REGEXP = /[.,\\/#!$%\\^&\\*;:{}=\\-_`~()]$/;
const TUI_LATIN_REGEXP = /[A-z]/;
const TUI_LATIN_AND_NUMBERS_REGEXP = /[A-z|0-9]/;

/**
 * Generated bundle index. Do not edit.
 */

export { DEFAULT_ICONS_PATH, MASK_CARET_TRAP, TUI_CACHE_BUSTING_PAYLOAD, TUI_DECIMAL_SYMBOLS, TUI_DEFAULT_MARKER_HANDLER, TUI_DEFAULT_NUMBER_FORMAT, TUI_DIGIT_REGEXP, TUI_EXPAND_LOADED, TUI_HINT_DIRECTIONS, TUI_ICON_ERROR, TUI_LAST_PUNCTUATION_MARK_REGEXP, TUI_LATIN_AND_NUMBERS_REGEXP, TUI_LATIN_REGEXP, TUI_LEADING_ZEROES_REGEXP, TUI_MASK_SYMBOLS_REGEXP, TUI_NON_DIGITS_REGEXP, TUI_NON_DIGIT_REGEXP, TUI_SCROLLABLE, TUI_SCROLL_INTO_VIEW, tuiEditingKeys };

class TuiComputedDocumentException extends Error {
  constructor() {
    super(ngDevMode ? 'Only use computedDocument after load event' : '');
  }
}
class TuiDocumentSelectionException extends Error {
  constructor() {
    super(ngDevMode ? 'Failed to get document selection' : '');
  }
}
class TuiInvalidDayException extends Error {
  constructor(day) {
    super(ngDevMode ? `Invalid day: ${day}` : '');
  }
}
class TuiInvalidMonthException extends Error {
  constructor(month) {
    super(ngDevMode ? `Invalid month: ${month}` : '');
  }
}
class TuiInvalidYearException extends Error {
  constructor(year) {
    super(ngDevMode ? `Invalid year: ${year}` : '');
  }
}
class TuiNoHostException extends Error {
  constructor() {
    super(ngDevMode ? 'Portals cannot be used without TuiPortalHostComponent' : '');
  }
}
class TuiOwnerDocumentException extends Error {
  constructor() {
    super(ngDevMode ? 'Element does not have ownerDocument' : '');
  }
}
class TuiPureException extends Error {
  constructor() {
    super(ngDevMode ? 'tuiPure can only be used with functions or getters' : '');
  }
}
class TuiTableSortKeyException extends Error {
  constructor() {
    super(ngDevMode ? 'Trying to sort with no key' : '');
  }
}
class TuiTsParserException extends Error {
  constructor() {
    super(ngDevMode ? 'TsFileParser: 1 component/module per ts-file' : '');
  }
}
class TuiValueChangesException extends Error {
  constructor() {
    super(ngDevMode ? 'Control does not have valueChanges' : '');
  }
}
class TuiValuePresentException extends Error {
  constructor() {
    super(ngDevMode ? 'Value must present' : '');
  }
}
class TuiXmlParsingException extends Error {
  constructor() {
    super(ngDevMode ? 'Error parsing XML string' : '');
  }
}

/**
 * Generated bundle index. Do not edit.
 */

export { TuiComputedDocumentException, TuiDocumentSelectionException, TuiInvalidDayException, TuiInvalidMonthException, TuiInvalidYearException, TuiNoHostException, TuiOwnerDocumentException, TuiPureException, TuiTableSortKeyException, TuiTsParserException, TuiValueChangesException, TuiValuePresentException, TuiXmlParsingException };

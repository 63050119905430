import { InjectionToken, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { tuiControlValueFactory } from '@taiga-ui/kit/utils';
import { tuiCreateToken, tuiProvideOptions, tuiCreateTokenFromFactory, TUI_FIRST_DAY, TUI_LAST_DAY, TUI_DEFAULT_STRINGIFY, TUI_DEFAULT_IDENTITY_MATCHER, ALWAYS_FALSE_HANDLER } from '@taiga-ui/cdk';
import { TuiCountryIsoCode, tuiExtractI18n } from '@taiga-ui/i18n';

/**
 * Stream that emits calendar data change
 */
const TUI_CALENDAR_DATE_STREAM = new InjectionToken('[TUI_CALENDAR_DATE_STREAM]');
function tuiDateStreamWithTransformer(transformer) {
  return {
    provide: TUI_CALENDAR_DATE_STREAM,
    deps: [[new Optional(), new Self(), NgControl], [new Optional(), transformer]],
    useFactory: tuiControlValueFactory
  };
}
const TUI_COUNTRIES_DEFAULT_MASKS = {
  [TuiCountryIsoCode.AD]: '+376###-###',
  [TuiCountryIsoCode.AE]: '+971-##-###-####',
  [TuiCountryIsoCode.AF]: '+93##-###-####',
  [TuiCountryIsoCode.AG]: '+1(268) ###-####',
  [TuiCountryIsoCode.AI]: '+1(264) ###-####',
  [TuiCountryIsoCode.AL]: '+355(###) ###-###',
  [TuiCountryIsoCode.AM]: '+374##-###-###',
  [TuiCountryIsoCode.AO]: '+244(###) ###-###',
  [TuiCountryIsoCode.AR]: '+54(###) ####-####',
  [TuiCountryIsoCode.AT]: '+43(###) ###-####',
  [TuiCountryIsoCode.AU]: '+61#-####-####',
  [TuiCountryIsoCode.AW]: '+297###-####',
  [TuiCountryIsoCode.AZ]: '+994##-###-##-##',
  [TuiCountryIsoCode.BA]: '+387 ###-####-##',
  [TuiCountryIsoCode.BB]: '+1(246) ###-####',
  [TuiCountryIsoCode.BD]: '+880##-###-###',
  [TuiCountryIsoCode.BE]: '+32(###) ###-###',
  [TuiCountryIsoCode.BF]: '+226##-##-####',
  [TuiCountryIsoCode.BG]: '+359(###) ###-###',
  [TuiCountryIsoCode.BH]: '+973####-####',
  [TuiCountryIsoCode.BI]: '+257##-##-####',
  [TuiCountryIsoCode.BJ]: '+229##-##-####',
  [TuiCountryIsoCode.BL]: '+590 ## ## ## ## ##',
  [TuiCountryIsoCode.BM]: '+1(441) ###-####',
  [TuiCountryIsoCode.BN]: '+673###-####',
  [TuiCountryIsoCode.BO]: '+591#-###-####',
  [TuiCountryIsoCode.BQ]: '+599-###-####',
  [TuiCountryIsoCode.BR]: '+55(##) ####-#####',
  [TuiCountryIsoCode.BS]: '+1(242) ###-####',
  [TuiCountryIsoCode.BT]: '+975#-###-###',
  [TuiCountryIsoCode.BW]: '+267##-###-###',
  [TuiCountryIsoCode.BY]: '+375(##) ###-##-##',
  [TuiCountryIsoCode.BZ]: '+501###-####',
  [TuiCountryIsoCode.CA]: '+1(###) ###-####',
  [TuiCountryIsoCode.CD]: '+243(###) ###-###',
  [TuiCountryIsoCode.CF]: '+236-##-##-####',
  [TuiCountryIsoCode.CG]: '+242##-###-####',
  [TuiCountryIsoCode.CH]: '+41##-###-####',
  [TuiCountryIsoCode.CI]: '+225##-##-###-###',
  [TuiCountryIsoCode.CL]: '+56#-####-####',
  [TuiCountryIsoCode.CM]: '+237#####-####',
  [TuiCountryIsoCode.CN]: '+86(###) ####-####',
  [TuiCountryIsoCode.CO]: '+57(###) ###-####',
  [TuiCountryIsoCode.CR]: '+506####-####',
  [TuiCountryIsoCode.CU]: '+53#-###-####',
  [TuiCountryIsoCode.CV]: '+238(###) ##-##',
  [TuiCountryIsoCode.CW]: '+5999-###-####',
  [TuiCountryIsoCode.CY]: '+357##-###-###',
  [TuiCountryIsoCode.CZ]: '+420(###) ###-###',
  [TuiCountryIsoCode.DE]: '+49(###) ###-###-##',
  [TuiCountryIsoCode.DJ]: '+253##-##-##-##',
  [TuiCountryIsoCode.DK]: '+45##-##-##-##',
  [TuiCountryIsoCode.DM]: '+1(767) ###-####',
  [TuiCountryIsoCode.DO]: '+1(###) ###-####',
  [TuiCountryIsoCode.DZ]: '+213##-###-####',
  [TuiCountryIsoCode.EC]: '+593##-###-####',
  [TuiCountryIsoCode.EE]: '+372####-####',
  [TuiCountryIsoCode.EG]: '+20(###) ###-####',
  [TuiCountryIsoCode.ER]: '+291#-###-###',
  [TuiCountryIsoCode.ES]: '+34(###) ###-###',
  [TuiCountryIsoCode.ET]: '+251##-###-####',
  [TuiCountryIsoCode.FI]: '+358(###) ###-##-##',
  [TuiCountryIsoCode.FJ]: '+679##-#####',
  [TuiCountryIsoCode.FK]: '+500#####',
  [TuiCountryIsoCode.FM]: '+691###-####',
  [TuiCountryIsoCode.FR]: '+33 ## ## ## ## ##',
  [TuiCountryIsoCode.GA]: '+241##-##-##-##',
  [TuiCountryIsoCode.GB]: '+44##-####-####',
  [TuiCountryIsoCode.GD]: '+1(473) ###-####',
  [TuiCountryIsoCode.GE]: '+995(###) ###-###',
  [TuiCountryIsoCode.GF]: '+594 ## ## ## ## ##',
  [TuiCountryIsoCode.GH]: '+233(###) ###-###',
  [TuiCountryIsoCode.GI]: '+350###-#####',
  [TuiCountryIsoCode.GL]: '+299##-##-##',
  [TuiCountryIsoCode.GM]: '+220(###) ##-##',
  [TuiCountryIsoCode.GN]: '+224##-###-###',
  [TuiCountryIsoCode.GP]: '+590 ## ## ## ## ##',
  [TuiCountryIsoCode.GQ]: '+240##-###-####',
  [TuiCountryIsoCode.GR]: '+30(###) ###-####',
  [TuiCountryIsoCode.GT]: '+502#-###-####',
  [TuiCountryIsoCode.GW]: '+245#-######',
  [TuiCountryIsoCode.GY]: '+592###-####',
  [TuiCountryIsoCode.HK]: '+852####-####',
  [TuiCountryIsoCode.HN]: '+504####-####',
  [TuiCountryIsoCode.HR]: '+385##-###-####',
  [TuiCountryIsoCode.HT]: '+509##-##-####',
  [TuiCountryIsoCode.HU]: '+36(###) ###-###',
  [TuiCountryIsoCode.ID]: '+62(###) ###-##-###',
  [TuiCountryIsoCode.IE]: '+353(###) ###-###',
  [TuiCountryIsoCode.IL]: '+972##-###-####',
  [TuiCountryIsoCode.IN]: '+91(####) ###-###',
  [TuiCountryIsoCode.IQ]: '+964(###) ###-####',
  [TuiCountryIsoCode.IR]: '+98(###) ###-####',
  [TuiCountryIsoCode.IS]: '+354###-####',
  [TuiCountryIsoCode.IT]: '+39(###) ####-###',
  [TuiCountryIsoCode.JM]: '+1(876) ###-####',
  [TuiCountryIsoCode.JO]: '+962#-####-####',
  [TuiCountryIsoCode.JP]: '+81-##-####-####',
  [TuiCountryIsoCode.KE]: '+254###-######',
  [TuiCountryIsoCode.KG]: '+996(###) ###-###',
  [TuiCountryIsoCode.KH]: '+855##-###-###',
  [TuiCountryIsoCode.KM]: '+269##-#####',
  [TuiCountryIsoCode.KN]: '+1(869) ###-####',
  [TuiCountryIsoCode.KP]: '+850####-#############',
  [TuiCountryIsoCode.KR]: '+82##-###-####',
  [TuiCountryIsoCode.KW]: '+965####-####',
  [TuiCountryIsoCode.KY]: '+1(345) ###-####',
  [TuiCountryIsoCode.KZ]: '+7(###) ###-##-##',
  [TuiCountryIsoCode.LA]: '+856##-##-###-###',
  [TuiCountryIsoCode.LB]: '+961##-###-###',
  [TuiCountryIsoCode.LC]: '+1(758) ###-####',
  [TuiCountryIsoCode.LI]: '+423(###) ###-####',
  [TuiCountryIsoCode.LK]: '+94##-###-####',
  [TuiCountryIsoCode.LR]: '+231##-###-###',
  [TuiCountryIsoCode.LS]: '+266#-###-####',
  [TuiCountryIsoCode.LT]: '+370(###) ##-###',
  [TuiCountryIsoCode.LU]: '+352(###) ###-###',
  [TuiCountryIsoCode.LV]: '+371##-###-###',
  [TuiCountryIsoCode.LY]: '+218##-###-####',
  [TuiCountryIsoCode.MA]: '+212##-####-###',
  [TuiCountryIsoCode.MC]: '+377###-###-###',
  [TuiCountryIsoCode.MD]: '+373####-####',
  [TuiCountryIsoCode.ME]: '+382##-###-###',
  [TuiCountryIsoCode.MF]: '+590 ## ## ## ## ##',
  [TuiCountryIsoCode.MG]: '+261##-##-#####',
  [TuiCountryIsoCode.MK]: '+389##-###-###',
  [TuiCountryIsoCode.ML]: '+223##-##-####',
  [TuiCountryIsoCode.MM]: '+95##-###-###',
  [TuiCountryIsoCode.MN]: '+976##-##-####',
  [TuiCountryIsoCode.MO]: '+853####-####',
  [TuiCountryIsoCode.MQ]: '+596 ## ## ## ## ##',
  [TuiCountryIsoCode.MR]: '+222##-##-####',
  [TuiCountryIsoCode.MS]: '+1(664) ###-####',
  [TuiCountryIsoCode.MT]: '+356####-####',
  [TuiCountryIsoCode.MU]: '+230####-####',
  [TuiCountryIsoCode.MV]: '+960###-####',
  [TuiCountryIsoCode.MW]: '+265#-####-####',
  [TuiCountryIsoCode.MX]: '+52(###) ###-####',
  [TuiCountryIsoCode.MY]: '+60(###) ###-###',
  [TuiCountryIsoCode.MZ]: '+258##-###-###',
  [TuiCountryIsoCode.NA]: '+264##-###-####',
  [TuiCountryIsoCode.NC]: '+687 ### ###',
  [TuiCountryIsoCode.NE]: '+227##-##-####',
  [TuiCountryIsoCode.NG]: '+234(###) ###-####',
  [TuiCountryIsoCode.NI]: '+505####-####',
  [TuiCountryIsoCode.NL]: '+31##-###-####',
  [TuiCountryIsoCode.NO]: '+47(###) ##-###',
  [TuiCountryIsoCode.NP]: '+977##-###-###',
  [TuiCountryIsoCode.NZ]: '+64(###) ###-####',
  [TuiCountryIsoCode.OM]: '+968##-###-###',
  [TuiCountryIsoCode.PA]: '+507###-####',
  [TuiCountryIsoCode.PE]: '+51(###) ###-###',
  [TuiCountryIsoCode.PF]: '+689 ## ## ## ##',
  [TuiCountryIsoCode.PG]: '+675(###) ##-###',
  [TuiCountryIsoCode.PH]: '+63(###) ###-####',
  [TuiCountryIsoCode.PK]: '+92(###) ###-####',
  [TuiCountryIsoCode.PL]: '+48(###) ###-###',
  [TuiCountryIsoCode.PT]: '+351##-###-####',
  [TuiCountryIsoCode.PW]: '+680###-####',
  [TuiCountryIsoCode.PY]: '+595(###) ###-###',
  [TuiCountryIsoCode.QA]: '+974####-####',
  [TuiCountryIsoCode.RE]: '+262 ## ## ## ## ##',
  [TuiCountryIsoCode.RO]: '+40##-###-####',
  [TuiCountryIsoCode.RS]: '+381##-###-####',
  [TuiCountryIsoCode.RU]: '+7### ###-##-##',
  [TuiCountryIsoCode.RW]: '+250(###) ###-###',
  [TuiCountryIsoCode.SA]: '+966#-####-####',
  [TuiCountryIsoCode.SB]: '+677###-####',
  [TuiCountryIsoCode.SC]: '+248#-###-###',
  [TuiCountryIsoCode.SD]: '+249##-###-####',
  [TuiCountryIsoCode.SE]: '+46##-###-####',
  [TuiCountryIsoCode.SG]: '+65####-####',
  [TuiCountryIsoCode.SH]: '+290####',
  [TuiCountryIsoCode.SI]: '+386##-###-###',
  [TuiCountryIsoCode.SK]: '+421(###) ###-###',
  [TuiCountryIsoCode.SL]: '+232##-######',
  [TuiCountryIsoCode.SM]: '+378####-######',
  [TuiCountryIsoCode.SN]: '+221##-###-####',
  [TuiCountryIsoCode.SO]: '+252##-###-###',
  [TuiCountryIsoCode.SR]: '+597###-####',
  [TuiCountryIsoCode.ST]: '+239##-#####',
  [TuiCountryIsoCode.SV]: '+503##-##-####',
  [TuiCountryIsoCode.SX]: '+1(721) ###-####',
  [TuiCountryIsoCode.SY]: '+963##-####-###',
  [TuiCountryIsoCode.SZ]: '+268##-##-####',
  [TuiCountryIsoCode.TC]: '+1(649) ###-####',
  [TuiCountryIsoCode.TD]: '+235##-##-##-##',
  [TuiCountryIsoCode.TG]: '+228##-###-###',
  [TuiCountryIsoCode.TH]: '+66##-###-####',
  [TuiCountryIsoCode.TJ]: '+992##-###-####',
  [TuiCountryIsoCode.TL]: '+670###-#####',
  [TuiCountryIsoCode.TM]: '+993#-###-####',
  [TuiCountryIsoCode.TN]: '+216##-###-###',
  [TuiCountryIsoCode.TO]: '+676#####',
  [TuiCountryIsoCode.TR]: '+90(###) ###-####',
  [TuiCountryIsoCode.TT]: '+1(868) ###-####',
  [TuiCountryIsoCode.TW]: '+886#-####-####',
  [TuiCountryIsoCode.TZ]: '+255##-###-####',
  [TuiCountryIsoCode.UA]: '+380(##) ###-##-##',
  [TuiCountryIsoCode.UG]: '+256(###) ###-###',
  [TuiCountryIsoCode.US]: '+1(###) ###-####',
  [TuiCountryIsoCode.UY]: '+598#-###-##-##',
  [TuiCountryIsoCode.UZ]: '+998##-###-####',
  [TuiCountryIsoCode.VC]: '+1(784) ###-####',
  [TuiCountryIsoCode.VE]: '+58(###) ###-####',
  [TuiCountryIsoCode.VG]: '+1(284)###-####',
  [TuiCountryIsoCode.VN]: '+84(###) ####-###',
  [TuiCountryIsoCode.VU]: '+678##-#####',
  [TuiCountryIsoCode.WS]: '+685##-####',
  [TuiCountryIsoCode.XK]: '+383##-###-###',
  [TuiCountryIsoCode.YE]: '+967###-###-###',
  [TuiCountryIsoCode.YT]: '+262 ## ## ## ## ##',
  [TuiCountryIsoCode.ZA]: '+27##-###-####',
  [TuiCountryIsoCode.ZM]: '+260##-###-####',
  [TuiCountryIsoCode.ZW]: '+263#-######'
};
const TUI_COUNTRIES_MASKS = tuiCreateToken(TUI_COUNTRIES_DEFAULT_MASKS);
function tuiCountriesMasksProvider(options) {
  return tuiProvideOptions(TUI_COUNTRIES_MASKS, options, TUI_COUNTRIES_DEFAULT_MASKS);
}

// TODO: Refactor to use `AbstractTuiValueTransformer` and add ability to provide it for all controls
/**
 * Control value transformer of TuiDay to custom value format for InputDate* components
 */
const TUI_DATE_VALUE_TRANSFORMER = new InjectionToken('[TUI_DATE_VALUE_TRANSFORMER]');
/**
 * Control value transformer for InputDateRange component
 */
const TUI_DATE_RANGE_VALUE_TRANSFORMER = new InjectionToken('[TUI_DATE_RANGE_VALUE_TRANSFORMER]');
/**
 * Control value transformer for InputDateTime component
 */
const TUI_DATE_TIME_VALUE_TRANSFORMER = new InjectionToken('[TUI_DATE_TIME_VALUE_TRANSFORMER]');
/**
 * Control value transformer for InputTime component
 */
const TUI_TIME_VALUE_TRANSFORMER = new InjectionToken('[TUI_TIME_VALUE_TRANSFORMER]');
const TUI_PROMPT_WORDS = tuiCreateTokenFromFactory(tuiExtractI18n('prompt'));
const TUI_CANCEL_WORD = tuiCreateTokenFromFactory(tuiExtractI18n('cancel'));
const TUI_DONE_WORD = tuiCreateTokenFromFactory(tuiExtractI18n('done'));
const TUI_MORE_WORD = tuiCreateTokenFromFactory(tuiExtractI18n('more'));
const TUI_HIDE_TEXT = tuiCreateTokenFromFactory(tuiExtractI18n('hide'));
const TUI_SHOW_ALL_TEXT = tuiCreateTokenFromFactory(tuiExtractI18n('showAll'));
const TUI_OTHER_DATE_TEXT = tuiCreateTokenFromFactory(tuiExtractI18n('otherDate'));
const TUI_CHOOSE_DAY_OR_RANGE_TEXTS = tuiCreateTokenFromFactory(tuiExtractI18n('mobileCalendarTexts'));
const TUI_FROM_TO_TEXTS = tuiCreateTokenFromFactory(tuiExtractI18n('range'));
const TUI_PLUS_MINUS_TEXTS = tuiCreateTokenFromFactory(tuiExtractI18n('countTexts'));
const TUI_TIME_TEXTS = tuiCreateTokenFromFactory(tuiExtractI18n('time'));
const TUI_DATE_TEXTS = tuiCreateTokenFromFactory(tuiExtractI18n('dateTexts'));
const TUI_DIGITAL_INFORMATION_UNITS = tuiCreateTokenFromFactory(tuiExtractI18n('digitalInformationUnits'));
const TUI_COPY_TEXTS = tuiCreateTokenFromFactory(tuiExtractI18n('copyTexts'));
const TUI_PASSWORD_TEXTS = tuiCreateTokenFromFactory(tuiExtractI18n('passwordTexts'));
const TUI_CALENDAR_MONTHS = tuiCreateTokenFromFactory(tuiExtractI18n('shortCalendarMonths'));
const TUI_FILE_TEXTS = tuiCreateTokenFromFactory(tuiExtractI18n('fileTexts'));
const TUI_PAGINATION_TEXTS = tuiCreateTokenFromFactory(tuiExtractI18n('pagination'));
const TUI_INPUT_FILE_TEXTS = tuiCreateTokenFromFactory(tuiExtractI18n('inputFileTexts'));
const TUI_MULTI_SELECT_TEXTS = tuiCreateTokenFromFactory(tuiExtractI18n('multiSelectTexts'));
const TUI_COUNTRIES = tuiCreateTokenFromFactory(tuiExtractI18n('countries'));
const TUI_INPUT_DATE_DEFAULT_OPTIONS = {
  icon: ({
    $implicit
  }) => $implicit === 's' ? 'tuiIconCalendar' : 'tuiIconCalendarLarge',
  min: TUI_FIRST_DAY,
  max: TUI_LAST_DAY,
  nativePicker: false
};
/**
 * Default parameters for InputDate component
 */
const TUI_INPUT_DATE_OPTIONS = tuiCreateToken(TUI_INPUT_DATE_DEFAULT_OPTIONS);
function tuiInputDateOptionsProvider(options) {
  return tuiProvideOptions(TUI_INPUT_DATE_OPTIONS, options, TUI_INPUT_DATE_DEFAULT_OPTIONS);
}
const TUI_DEFAULT_ITEMS_HANDLERS = {
  stringify: TUI_DEFAULT_STRINGIFY,
  identityMatcher: TUI_DEFAULT_IDENTITY_MATCHER,
  disabledItemHandler: ALWAYS_FALSE_HANDLER
};
/**
 * Default items handlers for components
 */
const TUI_ITEMS_HANDLERS = tuiCreateToken(TUI_DEFAULT_ITEMS_HANDLERS);
function tuiItemsHandlersProvider(options) {
  return tuiProvideOptions(TUI_ITEMS_HANDLERS, options, TUI_DEFAULT_ITEMS_HANDLERS);
}

/**
 * A component for mobile data picker
 */
const TUI_MOBILE_CALENDAR = new InjectionToken('[TUI_MOBILE_CALENDAR]');

/**
 * A function to get localized formatted month
 */
const TUI_MONTH_FORMATTER = new InjectionToken('[TUI_MONTH_FORMATTER]');

/**
 * Margin between tabs
 */
const TUI_TAB_MARGIN = tuiCreateToken(24);

/**
 * Validation errors
 */
const TUI_VALIDATION_ERRORS = tuiCreateToken({});

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_CALENDAR_DATE_STREAM, TUI_CALENDAR_MONTHS, TUI_CANCEL_WORD, TUI_CHOOSE_DAY_OR_RANGE_TEXTS, TUI_COPY_TEXTS, TUI_COUNTRIES, TUI_COUNTRIES_DEFAULT_MASKS, TUI_COUNTRIES_MASKS, TUI_DATE_RANGE_VALUE_TRANSFORMER, TUI_DATE_TEXTS, TUI_DATE_TIME_VALUE_TRANSFORMER, TUI_DATE_VALUE_TRANSFORMER, TUI_DEFAULT_ITEMS_HANDLERS, TUI_DIGITAL_INFORMATION_UNITS, TUI_DONE_WORD, TUI_FILE_TEXTS, TUI_FROM_TO_TEXTS, TUI_HIDE_TEXT, TUI_INPUT_DATE_DEFAULT_OPTIONS, TUI_INPUT_DATE_OPTIONS, TUI_INPUT_FILE_TEXTS, TUI_ITEMS_HANDLERS, TUI_MOBILE_CALENDAR, TUI_MONTH_FORMATTER, TUI_MORE_WORD, TUI_MULTI_SELECT_TEXTS, TUI_OTHER_DATE_TEXT, TUI_PAGINATION_TEXTS, TUI_PASSWORD_TEXTS, TUI_PLUS_MINUS_TEXTS, TUI_PROMPT_WORDS, TUI_SHOW_ALL_TEXT, TUI_TAB_MARGIN, TUI_TIME_TEXTS, TUI_TIME_VALUE_TRANSFORMER, TUI_VALIDATION_ERRORS, tuiCountriesMasksProvider, tuiDateStreamWithTransformer, tuiInputDateOptionsProvider, tuiItemsHandlersProvider };

import { Environment } from '../app/types/environment';

export const environment: Environment = {
  production: true,
  productionUrl: 'https://customsclear.by',
  apiUrl: 'https://customsclear.by/api',
  webUrl: 'https://web.declarant.by/api',
  websocketUrl: 'wss://customsclear.by/api/notifier/ws',
  googleApiKey: 'AIzaSyBE3H2WzKW73LSDfkVnPwUKSXmB9JevrRU',
  googleAppId: '35df6fbae76c046a6',
};

import { tuiGetViewportWidth } from '@taiga-ui/core/utils/dom';
function tuiIsMobile(win, {
  mobile
}) {
  return tuiGetViewportWidth(win) < mobile;
}

/**
 * Generated bundle index. Do not edit.
 */

export { tuiIsMobile };

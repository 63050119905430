import { tuiAssert } from '@taiga-ui/cdk/classes';
import { TuiPureException } from '@taiga-ui/cdk/exceptions';

/**
 * @deprecated:
 * not compatible with TypeScript 5
 */
function tuiDebounce(timeout) {
  let timeoutRef;
  return function (_target, _key, descriptor) {
    const {
      value
    } = descriptor;
    descriptor.value = function (...args) {
      clearTimeout(timeoutRef);
      timeoutRef = setTimeout(() => value.apply(this, args), timeout);
    };
    return descriptor;
  };
}
function errorGetDefault(key, component) {
  return `Default value for ${String(key)} was not provided in ${component}, error in Taiga UI Angular Kit`;
}
function errorSetDefault(key, component) {
  return `Undefined was passed as ${String(key)} to ${component}, which is invalid input, using default value:`;
}
function errorSetDefaultInitial(key, component) {
  return `Undefined was passed as default value for ${String(key)} to ${component}, error in Taiga UI Angular Kit`;
}
/**
 * @deprecated:
 * not compatible with TypeScript 5
 *
 * Decorator for checking input values for undefined. You can also pass
 * optional assertion to check input against.
 *
 * CAUTION: This decorator overwrites other getters and setters.
 */
function tuiDefaultProp(assertion, ...args) {
  return (target, key) => {
    const {
      name
    } = target.constructor;
    const errorGetDefaultMessage = ngDevMode && errorGetDefault(key, name);
    const errorSetDefaultMessage = ngDevMode && errorSetDefault(key, name);
    Object.defineProperty(target, key, {
      configurable: true,
      get() {
        ngDevMode && tuiAssert.assert(false, errorGetDefaultMessage);
        return undefined;
      },
      set(initialValue) {
        const isValid = initialValue !== undefined;
        const errorMessage = ngDevMode && errorSetDefaultInitial(key, name);
        let currentValue = initialValue;
        ngDevMode && tuiAssert.assert(isValid, errorMessage);
        if (ngDevMode && isValid && assertion && tuiAssert) {
          tuiAssert.assert(assertion.call(this, initialValue), `${String(key)} in ${name} received:`, initialValue, ...args);
        }
        Object.defineProperty(this, key, {
          configurable: true,
          get() {
            return currentValue;
          },
          set(value) {
            const isValid = value !== undefined;
            const backupValue = initialValue;
            ngDevMode && tuiAssert.assert(isValid, errorSetDefaultMessage, String(backupValue));
            if (ngDevMode && isValid && assertion && tuiAssert) {
              tuiAssert.assert(assertion.call(this, value), `${String(key)} in ${name} received:`, value, ...args);
            }
            currentValue = isValid ? value : backupValue;
          }
        });
      }
    });
  };
}
function decorateMethod(originalMethod) {
  let previousArgs = [];
  let originalFnWasCalledLeastAtOnce = false;
  let pureValue;
  return function tuiPureMethodPatched(...args) {
    const isPure = originalFnWasCalledLeastAtOnce && previousArgs.length === args.length && args.every((arg, index) => arg === previousArgs[index]);
    if (isPure) {
      return pureValue;
    }
    previousArgs = args;
    pureValue = originalMethod.apply(this, args);
    originalFnWasCalledLeastAtOnce = true;
    return pureValue;
  };
}
function decorateGetter(originalGetter, propertyKey, enumerable = true) {
  return function tuiPureGetterPatched() {
    const value = originalGetter.call(this);
    Object.defineProperty(this, propertyKey, {
      enumerable,
      value
    });
    return value;
  };
}
function tuiPure(target, propertyKeyOrContext, descriptor) {
  if (typeof target === 'function') {
    const context = propertyKeyOrContext;
    if (context.kind === 'getter') {
      return decorateGetter(target, context.name);
    }
    if (context.kind === 'method') {
      return decorateMethod(target);
    }
    throw new TuiPureException();
  }
  const {
    get,
    enumerable,
    value
  } = descriptor;
  const propertyKey = propertyKeyOrContext;
  if (get) {
    return {
      configurable: true,
      enumerable,
      get: decorateGetter(get, propertyKey, enumerable)
    };
  }
  if (typeof value !== 'function') {
    throw new TuiPureException();
  }
  const original = value;
  return {
    configurable: true,
    enumerable,
    get() {
      let previousArgs = [];
      let originalFnWasCalledLeastAtOnce = false;
      let pureValue;
      const patched = (...args) => {
        const isPure = originalFnWasCalledLeastAtOnce && previousArgs.length === args.length && args.every((arg, index) => arg === previousArgs[index]);
        if (isPure) {
          return pureValue;
        }
        previousArgs = args;
        pureValue = original.apply(this, args);
        originalFnWasCalledLeastAtOnce = true;
        return pureValue;
      };
      Object.defineProperty(this, propertyKey, {
        configurable: true,
        value: patched
      });
      return patched;
    }
  };
}
function errorSet(key, component) {
  return `Undefined was passed as ${String(key)} to ${component}, setter will not be called`;
}
/**
 * @deprecated:
 * not compatible with TypeScript 5
 *
 * Decorator for checking input setter values against a custom assertion which
 * takes value passed to input setter and component instance as arguments.
 * It specifically checks for undefined values and prevents calls to the
 * original setter in this case.
 */
function tuiRequiredSetter(assertion, ...args) {
  return (target, key, {
    configurable,
    enumerable,
    get,
    set
  }) => {
    const {
      name
    } = target.constructor;
    return {
      configurable,
      enumerable,
      get,
      set(value) {
        if (ngDevMode && value !== undefined && assertion && tuiAssert) {
          tuiAssert.assert(assertion.call(this, value), `${String(key)} in ${name} received:`, value, ...args);
        }
        if (!set || value === undefined) {
          ngDevMode && tuiAssert.assert(value !== undefined, errorSet(key, name));
          return;
        }
        set.call(this, value);
      }
    };
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { tuiDebounce, tuiDefaultProp, tuiPure, tuiRequiredSetter };

import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { inject, InjectFlags, RendererFactory2, InjectionToken, PLATFORM_ID, ElementRef } from '@angular/core';
import { WINDOW, NAVIGATOR, USER_AGENT } from '@ng-web-apis/common';
import { tuiTypedFromEvent } from '@taiga-ui/cdk/observables';
import { tuiCreateTokenFromFactory, tuiGetDocumentOrShadowRoot, tuiGetActualTarget, tuiCreateToken, tuiIsIos, tuiIsApple } from '@taiga-ui/cdk/utils';
import { BehaviorSubject, timer, merge, of, from, EMPTY } from 'rxjs';
import { switchMap, map, startWith, share, filter, takeUntil, repeatWhen, withLatestFrom, take, distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { ɵAnimationEngine } from '@angular/animations/browser';
import { ALWAYS_FALSE_HANDLER, ALWAYS_TRUE_HANDLER } from '@taiga-ui/cdk/constants';
import { tuiCreateTokenFromFactory as tuiCreateTokenFromFactory$1 } from '@taiga-ui/cdk/utils/miscellaneous';

/**
 * Element currently being removed by AnimationEngine
 */
const TUI_REMOVED_ELEMENT = tuiCreateTokenFromFactory(() => {
  const stub = {
    onRemovalComplete: () => {}
  };
  const element$ = new BehaviorSubject(null);
  const engine = inject(ɵAnimationEngine, InjectFlags.Optional) || stub;
  const {
    onRemovalComplete = stub.onRemovalComplete
  } = engine;
  engine.onRemovalComplete = (element, context) => {
    element$.next(element);
    onRemovalComplete.call(engine, element, context);
  };
  return element$.pipe(switchMap(element => timer(0).pipe(map(() => null), startWith(element))), share());
});

// Checks if focusout event should be considered leaving active zone
function isValidFocusout(target, removedElement = null) {
  return (
    // Not due to switching tabs/going to DevTools
    tuiGetDocumentOrShadowRoot(target).activeElement !== target &&
    // Not due to button/input becoming disabled or under disabled fieldset
    !target.matches(':disabled') &&
    // Not due to element being removed from DOM
    !(removedElement === null || removedElement === void 0 ? void 0 : removedElement.contains(target))
  );
}
function shadowRootActiveElement(root) {
  return merge(tuiTypedFromEvent(root, 'focusin').pipe(map(({
    target
  }) => target)), tuiTypedFromEvent(root, 'focusout').pipe(filter(({
    target,
    relatedTarget
  }) => !!relatedTarget && isValidFocusout(target)), map(({
    relatedTarget
  }) => relatedTarget)));
}
/**
 * Active element on the document for ActiveZone
 */
const TUI_ACTIVE_ELEMENT = tuiCreateTokenFromFactory(() => {
  const removedElement$ = inject(TUI_REMOVED_ELEMENT);
  const win = inject(WINDOW);
  const doc = inject(DOCUMENT);
  const focusout$ = tuiTypedFromEvent(win, 'focusout');
  const focusin$ = tuiTypedFromEvent(win, 'focusin');
  const blur$ = tuiTypedFromEvent(win, 'blur');
  const mousedown$ = tuiTypedFromEvent(win, 'mousedown');
  const mouseup$ = tuiTypedFromEvent(win, 'mouseup');
  return merge(focusout$.pipe(
  // eslint-disable-next-line rxjs/no-unsafe-takeuntil
  takeUntil(mousedown$),
  /**
   * TODO: replace to
   * repeat({delay: () => mouseup$})
   * in RxJS 7
   */
  // eslint-disable-next-line rxjs/no-ignored-notifier
  repeatWhen(() => mouseup$), withLatestFrom(removedElement$), filter(([event, removedElement]) => isValidFocusout(tuiGetActualTarget(event), removedElement)), map(([{
    relatedTarget
  }]) => relatedTarget)), blur$.pipe(map(() => doc.activeElement), filter(element => !!(element === null || element === void 0 ? void 0 : element.matches('iframe')))), focusin$.pipe(switchMap(event => {
    const target = tuiGetActualTarget(event);
    const root = tuiGetDocumentOrShadowRoot(target);
    return root === doc ? of(target) : shadowRootActiveElement(root).pipe(startWith(target));
  })), mousedown$.pipe(switchMap(event => {
    const actualTargetInCurrentTime = tuiGetActualTarget(event);
    return !doc.activeElement || doc.activeElement === doc.body ? of(actualTargetInCurrentTime) : focusout$.pipe(take(1), map(
    /**
     * Do not use `map(() => tuiGetActualTarget(event))`
     * because we have different result in runtime
     */
    () => actualTargetInCurrentTime), takeUntil(timer(0)));
  }))).pipe(distinctUntilChanged(), share());
});

/**
 * A stream of alerts
 */
const TUI_ALERTS = tuiCreateToken([]);
function tuiAsAlerts(useExisting) {
  return {
    provide: TUI_ALERTS,
    multi: true,
    useExisting
  };
}
const TUI_BASE_HREF = tuiCreateTokenFromFactory(() => {
  var _a, _b;
  return (_b = (_a = inject(DOCUMENT).querySelector('base')) === null || _a === void 0 ? void 0 : _a.href) !== null && _b !== void 0 ? _b : '';
});

/**
 * A Renderer2 for global services
 * https://github.com/angular/angular/issues/17824#issuecomment-353239017
 * @deprecated unnecessary
 * TODO: remove in 4.0
 */
const TUI_DEFAULT_RENDERER = tuiCreateTokenFromFactory(
// @ts-ignore
() => inject(RendererFactory2).createRenderer(null, null));

/**
 * A stream of dialogs
 */
const TUI_DIALOGS = tuiCreateToken([]);
// TODO: Refactor dialogs in 4.0 so there is no need for module imports
function tuiAsDialog(useExisting) {
  return {
    provide: TUI_DIALOGS,
    multi: true,
    useExisting
  };
}

/**
 * @deprecated: use {@link TUI_BASE_HREF}
 */
const TUI_ENSURE_BASE_HREF = tuiCreateTokenFromFactory(() => {
  var _a;
  const baseHref = (_a = inject(DOCUMENT).querySelector('base')) === null || _a === void 0 ? void 0 : _a.href;
  if (baseHref) {
    return baseHref;
  }
  const link = inject(DOCUMENT).createElement('a');
  link.href = '';
  return link.pathname;
});

/**
 * A component that can be focused
 */
const TUI_FOCUSABLE_ITEM_ACCESSOR = new InjectionToken('[TUI_FOCUSABLE_ITEM_ACCESSOR]');
function tuiAsFocusableItemAccessor(useExisting) {
  return {
    provide: TUI_FOCUSABLE_ITEM_ACCESSOR,
    useExisting
  };
}
const TUI_FONTS_READY = new InjectionToken('[TUI_FONTS_READY]', {
  factory: () => {
    var _a;
    return from(((_a = inject(DOCUMENT).fonts) === null || _a === void 0 ? void 0 : _a.ready) || EMPTY).pipe(shareReplay({
      bufferSize: 1,
      refCount: false
    }));
  }
});

/**
 * iOS browser detection
 */
const TUI_IS_IOS = tuiCreateTokenFromFactory(() => tuiIsIos(inject(NAVIGATOR)));

// https://stackoverflow.com/a/11381730/2706426 http://detectmobilebrowsers.com/
const firstRegex = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/;
const secondRegex = /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/;
/**
 * Mobile browser detection
 */
const TUI_IS_MOBILE = tuiCreateTokenFromFactory(() => firstRegex.test(inject(USER_AGENT).toLowerCase()) || secondRegex.test(inject(USER_AGENT).slice(0, 4).toLowerCase()));

/**
 * Mobile browser that is not iOS (technically includes Windows Phone, Blackberry etc.)
 */
const TUI_IS_ANDROID = tuiCreateTokenFromFactory(() => inject(TUI_IS_MOBILE) && !inject(TUI_IS_IOS));

/**
 * Apple(safari/webkit) detection
 */
const TUI_IS_APPLE = tuiCreateTokenFromFactory(() => tuiIsApple(inject(NAVIGATOR)));

/**
 * Chromium browser engine detection
 */
const TUI_IS_CHROMIUM = tuiCreateTokenFromFactory(() => !!inject(WINDOW).chrome);

/**
 * Detect if app is running under Cypress
 * {@link https://docs.cypress.io/faq/questions/using-cypress-faq#Is-there-any-way-to-detect-if-my-app-is-running-under-Cypress Cypress docs}
 */
const TUI_IS_CYPRESS = tuiCreateTokenFromFactory(() => !!inject(WINDOW).Cypress);

/**
 * Detect if app is running under Playwright
 */
const TUI_IS_PLAYWRIGHT = tuiCreateTokenFromFactory(ALWAYS_FALSE_HANDLER);

/**
 * Detect if app is running under any of test frameworks
 */
const TUI_IS_E2E = tuiCreateTokenFromFactory(() => inject(TUI_IS_CYPRESS) || inject(TUI_IS_PLAYWRIGHT));

/**
 * Firefox browser engine detection
 */
const TUI_IS_FIREFOX = tuiCreateTokenFromFactory(() => {
  var _a;
  return typeof ((_a = inject(WINDOW)) === null || _a === void 0 ? void 0 : _a.mozCancelFullScreen) !== 'undefined';
});
const TUI_IS_STACKBLITZ = tuiCreateTokenFromFactory(() => inject(WINDOW).location.host.endsWith('stackblitz.io'));
const TUI_IS_TOUCH = tuiCreateTokenFromFactory$1(() => inject(WINDOW).matchMedia('(pointer: coarse)').matches);

/**
 * Webkit browser engine detection
 */
const TUI_IS_WEBKIT = tuiCreateTokenFromFactory(() => {
  var _a;
  return !!((_a = inject(WINDOW)) === null || _a === void 0 ? void 0 : _a.webkitConvertPointFromNodeToPage);
});

// TODO: Switch to factory in 4.0
const TUI_PLATFORM = tuiCreateToken('web');
function tuiPlatformFactory() {
  if (inject(TUI_IS_IOS)) {
    return 'ios';
  }
  if (inject(TUI_IS_ANDROID)) {
    return 'android';
  }
  return 'web';
}

/**
 * SSR safe default empty Range
 */
const TUI_RANGE = tuiCreateTokenFromFactory(() => isPlatformBrowser(inject(PLATFORM_ID)) ? new Range() : {});

/**
 * Scrollable container
 */
const TUI_SCROLL_REF = tuiCreateTokenFromFactory(() => new ElementRef(inject(DOCUMENT).documentElement));

/**
 * Swipe options
 */
const TUI_SWIPE_OPTIONS = tuiCreateToken({
  timeout: 500,
  threshold: 30
});

/**
 * @description:
 * The isTrusted read-only property of the Event interface is a boolean value that is true
 * when the event was generated by a user action, and false when the event was created or
 * modified by a script or dispatched via EventTarget.dispatchEvent().
 */
const TUI_TAKE_ONLY_TRUSTED_EVENTS = tuiCreateTokenFromFactory(ALWAYS_TRUE_HANDLER);
const TUI_TOUCH_SUPPORTED = tuiCreateTokenFromFactory(() => inject(WINDOW).matchMedia('(any-pointer: coarse)').matches);
const TUI_WINDOW_SIZE = new InjectionToken('[TUI_WINDOW_SIZE]', {
  factory: () => {
    const w = inject(WINDOW);
    return tuiTypedFromEvent(w, 'resize').pipe(startWith(null), map(() => {
      var _a, _b;
      const width = Math.max(w.document.documentElement.clientWidth || 0, w.innerWidth || 0, ((_a = w.visualViewport) === null || _a === void 0 ? void 0 : _a.width) || 0);
      const height = Math.max(w.document.documentElement.clientHeight || 0, w.innerHeight || 0, ((_b = w.visualViewport) === null || _b === void 0 ? void 0 : _b.height) || 0);
      return {
        width,
        height,
        top: 0,
        left: 0,
        right: width,
        bottom: height
      };
    }), shareReplay({
      bufferSize: 1,
      refCount: true
    }));
  }
});

/**
 * @deprecated Use {@link TUI_WINDOW_SIZE} instead
 */
const TUI_WINDOW_HEIGHT = tuiCreateTokenFromFactory(() => inject(TUI_WINDOW_SIZE).pipe(map(({
  height
}) => height)));

/**
 * Zoom options
 */
const TUI_ZOOM_OPTIONS = tuiCreateToken({
  wheelSensitivity: 0.01
});

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_ACTIVE_ELEMENT, TUI_ALERTS, TUI_BASE_HREF, TUI_DEFAULT_RENDERER, TUI_DIALOGS, TUI_ENSURE_BASE_HREF, TUI_FOCUSABLE_ITEM_ACCESSOR, TUI_FONTS_READY, TUI_IS_ANDROID, TUI_IS_APPLE, TUI_IS_CHROMIUM, TUI_IS_CYPRESS, TUI_IS_E2E, TUI_IS_FIREFOX, TUI_IS_IOS, TUI_IS_MOBILE, TUI_IS_PLAYWRIGHT, TUI_IS_STACKBLITZ, TUI_IS_TOUCH, TUI_IS_WEBKIT, TUI_PLATFORM, TUI_RANGE, TUI_REMOVED_ELEMENT, TUI_SCROLL_REF, TUI_SWIPE_OPTIONS, TUI_TAKE_ONLY_TRUSTED_EVENTS, TUI_TOUCH_SUPPORTED, TUI_WINDOW_HEIGHT, TUI_WINDOW_SIZE, TUI_ZOOM_OPTIONS, tuiAsAlerts, tuiAsDialog, tuiAsFocusableItemAccessor, tuiPlatformFactory };

import { inject } from '@angular/core';
import { TUI_LANGUAGE } from '@taiga-ui/i18n/tokens';
import { isObservable, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
function tuiExtractI18n(key) {
  return () => inject(TUI_LANGUAGE).pipe(switchMap(streamOrValue => isObservable(streamOrValue) ? streamOrValue : of(streamOrValue)), map(lang => lang[key]));
}

/**
 * Generated bundle index. Do not edit.
 */

export { tuiExtractI18n };

import { TUI_CACHE_BUSTING_PAYLOAD, DEFAULT_ICONS_PATH, tuiEditingKeys } from '@taiga-ui/core/constants';
import { tuiGetElementObscures } from '@taiga-ui/cdk';
const ITEM_SIZE = 1.5;
const CONTENT_SIZE = 2.5;
function tuiGetBorder(hasIcon, hasCleaner = false, hasTooltip = false, hasContent = false, size = 'm') {
  const offset = size === 's' ? 0 : 0.25;
  let border = 0;
  if (hasIcon) {
    border += ITEM_SIZE + offset;
  }
  if (hasCleaner) {
    border += ITEM_SIZE + offset;
  }
  if (hasTooltip) {
    border += ITEM_SIZE + offset;
  }
  if (hasContent) {
    border += CONTENT_SIZE + offset;
  }
  return border;
}
function tuiIconsPathFactory(staticPath) {
  const base = staticPath.endsWith('/') ? staticPath : `${staticPath}/`;
  return name => {
    if (name.startsWith('tuiIcon')) {
      return `${base}${name}.svg${TUI_CACHE_BUSTING_PAYLOAD}#${name}`;
    }
    return DEFAULT_ICONS_PATH(name);
  };
}

/**
 * Check if pressed key is interactive in terms of input field
 */
function tuiIsEditingKey(key) {
  return key.length === 1 || tuiEditingKeys.includes(key);
}

/**
 * @internal
 */
function tuiIsObscured(el, exceptSelector = 'tui-hints-host') {
  var _a;
  return !!((_a = tuiGetElementObscures(el)) === null || _a === void 0 ? void 0 : _a.some(el => !el.closest(exceptSelector)));
}
function tuiIsPresumedHTMLString(candidate) {
  const trimmed = candidate.trim();
  return trimmed.startsWith('<') && trimmed.endsWith('>');
}
function tuiOverrideOptions(override, fallback) {
  return (directive, options) => {
    const result = directive || Object.assign({}, options || fallback);
    Object.keys(override).forEach(key => {
      // Update directive props with new defaults before inputs are processed
      result[key] = override[key];
    });
    return result;
  };
}
const SIZES = {
  xxs: 0,
  xs: 1,
  s: 2,
  m: 3,
  l: 4,
  xl: 5,
  xxl: 6
};
/**
 * Compares size constants to determine if first size is bigger than the second
 *
 * @param size size that we need to compare
 * @param biggerThanSize size to compare with, 's' by default
 */
function tuiSizeBigger(size, biggerThanSize = 's') {
  return SIZES[size] > SIZES[biggerThanSize];
}

/**
 * Generated bundle index. Do not edit.
 */

export { tuiGetBorder, tuiIconsPathFactory, tuiIsEditingKey, tuiIsObscured, tuiIsPresumedHTMLString, tuiOverrideOptions, tuiSizeBigger };
